//Fetch all cryptos types (for table)
export const FETCH_CRYPTOS_REQUEST = 'FETCH_CRYPTOS_REQUEST'
export const FETCH_CRYPTOS_SUCCES = 'FETCH_CRYPTOS_SUCCES'
export const FETCH_CRYPTOS_FAILURE = 'FETCH_CRYPTOS_FAILURE'

//Fetch all cryptos types (for table)
export const FETCH_ALL_CRYPTOS_REQUEST = 'FETCH_ALL_CRYPTOS_REQUEST'
export const FETCH_ALL_CRYPTOS_SUCCES = 'FETCH_ALL_CRYPTOS_SUCCES'
export const FETCH_ALL_CRYPTOS_FAILURE = 'FETCH_ALL_CRYPTOS_FAILURE'

//Fetch Trending cryptos types
export const FETCH_TRENDING_CRYPTOS_REQUEST = 'FETCH_TRENDING_CRYPTOS_REQUEST'
export const FETCH_TRENDING_CRYPTOS_SUCCES = 'FETCH_TRENDING_CRYPTOS_SUCCES'
export const FETCH_TRENDING_CRYPTOS_FAILURE = 'FETCH_TRENDING_CRYPTOS_FAILURE'

//Fetch One crypto types
export const FETCH_ONE_CRYPTO_REQUEST = 'FETCH_ONE_CRYPTO_REQUEST'
export const FETCH_ONE_CRYPTO_SUCCES = 'FETCH_ONE_CRYPTO_SUCCES'
export const FETCH_ONE_CRYPTO_FAILURE = 'FETCH_ONE_CRYPTO_FAILURE'